var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"add-media-modal-1",attrs:{"centered":"","hide-footer":"","hide-header":"","no-close-on-backdrop":"","no-close-on-esc":"","disabled":_vm.spinner},on:{"hide":_vm.onModalHide}},[_c('div',{staticClass:"\n      add-media-modal\n      d-flex\n      justify-content-center\n      align-content-center align-items-center\n    "},[_c('b-row',{staticStyle:{"display":"block"}},[_c('div',{staticClass:"closediv"},[_c('b-button',{staticClass:"closebtn",attrs:{"variant":"transparent"},on:{"click":function($event){return _vm.hide()}}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"XIcon"}})],1)],1),_c('div',{staticClass:"\n          d-inline-block d-flex\n          justify-content-center\n          align-content-center align-items-center\n          mt-2\n          mb-0\n        "},[_c('p',{staticClass:"font-weight-bolder heading1"},[_vm._v(" Add Media ")])]),_c('div',{staticClass:"\n          d-inline-block\n          mb-1\n          mt-0\n          d-flex\n          justify-content-center\n          align-content-center align-items-center\n        "},[_c('div',{staticClass:"form pb-2 w-100",attrs:{"id":"form"}},[_c('validation-observer',{ref:"addMediaForm"},[_c('b-form',{staticClass:"p-0",on:{"submit":function($event){$event.preventDefault();}}},[_c('div',[_c('b-form-group',{staticClass:"label",attrs:{"text-bolder":"","label":"Select Type"}},[_c('validation-provider',{attrs:{"name":"Select Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"label",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.option,"clearable":false,"disabled":_vm.spinner},on:{"input":_vm.selectChangeHandler},model:{value:(_vm.selectedTitle),callback:function ($$v) {_vm.selectedTitle=$$v},expression:"selectedTitle"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(
                  _vm.selectedTitle &&
                    _vm.selectedTitle.title &&
                    _vm.selectedTitle.title === 'Image'
                )?_c('div',{staticClass:"mb-1"},[_c('b-form-group',{staticClass:"label",attrs:{"text-bolder":"","label":"Duration (seconds)"}},[_c('validation-provider',{attrs:{"name":"Image Duration"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{staticClass:"enable-spinners",attrs:{"id":"number","type":"number","placeholder":"Please enter Image Duration","min":"5","max":"36000","disabled":_vm.spinner},on:{"input":function($event){return _vm.validateDuration(_vm.imageDuration)}},model:{value:(_vm.imageDuration),callback:function ($$v) {_vm.imageDuration=$$v},expression:"imageDuration"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors.length > 0 ? errors[0] : _vm.errorMessage))])]}}],null,false,12684669)})],1)],1):_vm._e(),(_vm.selectedTitle.title === 'Video')?_c('div',[_c('b-form-group',{staticClass:"label",attrs:{"label":"Select Orientation","label-size":"sm"}},[_c('b-form-radio-group',{attrs:{"name":"orientationRadios"},model:{value:(_vm.selectedOrientation),callback:function ($$v) {_vm.selectedOrientation=$$v},expression:"selectedOrientation"}},[_c('b-form-radio',{staticClass:"small-radio mt-1",attrs:{"value":"horizontal","disabled":_vm.spinner}},[_vm._v(" Horizontal ")]),_c('b-form-radio',{staticClass:"small-radio",attrs:{"value":"vertical","disabled":_vm.spinner}},[_vm._v(" Vertical ")])],1)],1),(_vm.selectedOrientation)?_c('div',[_c('b-form-group',{staticClass:"label",attrs:{"label":"Select Resolution"}},[_c('v-select',{attrs:{"options":_vm.resolutionOptions,"disabled":_vm.spinner,"placeholder":"Choose resolution"},model:{value:(_vm.selectedResolution),callback:function ($$v) {_vm.selectedResolution=$$v},expression:"selectedResolution"}})],1)],1):_vm._e()],1):_vm._e(),(_vm.userRole === _vm.AccountTypes.ADMIN)?_c('div',[_c('b-form-group',{staticClass:"label",attrs:{"text-bolder":"","label":"Select Business"}},[_c('validation-provider',{attrs:{"name":"Select Business","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","options":_vm.businessOptions,"filterable":false,"disabled":_vm.spinner},on:{"open":_vm.onOpen,"close":_vm.onClose},scopedSlots:_vm._u([{key:"list-footer",fn:function(){return [(_vm.hasNextPage)?_c('li',{ref:"load",staticClass:"loader"},[_c('b-skeleton',{attrs:{"animation":"fade","width":"85%"}}),_c('b-skeleton',{attrs:{"animation":"fade","width":"55%"}})],1):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.selectedBusiness),callback:function ($$v) {_vm.selectedBusiness=$$v},expression:"selectedBusiness"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2442424293)})],1)],1):_vm._e(),_c('div',{staticClass:"\n                  d-flex\n                  flex-column\n                  justify-content-center\n                  align-items-center\n                  imageDiv\n                "},[_c('b-img',{staticClass:"upload-video-image-div",attrs:{"src":_vm.thumbnailImg
                      ? _vm.thumbnailImg
                      : require('@/assets/images/simiicons/Image(2).svg')}}),_c('div',{staticClass:"d-flex w-100 justify-content-center"},[_c('b-button',{staticClass:"button mt-2 mx-4",attrs:{"variant":"primary","block":"","disabled":_vm.spinner},on:{"click":_vm.getFileAndSaveToMediaStorage}},[(_vm.spinner)?_c('div',[_c('b-spinner',{attrs:{"sm":"","label":"Loading..."}})],1):_c('span',[_vm._v("Add")])])],1),_c('div',{staticClass:"small px-2"},[_vm._v(" "+_vm._s(_vm.errorMsg ? _vm.errorMsg : null)+" ")])],1)])],1)],1)])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }