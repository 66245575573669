<template>
  <b-modal
    ref="add-media-modal-1"
    centered
    hide-footer
    hide-header
    no-close-on-backdrop
    no-close-on-esc
    :disabled="spinner"
    @hide="onModalHide"
  >
    <div
      class="
        add-media-modal
        d-flex
        justify-content-center
        align-content-center align-items-center
      "
    >
      <b-row style="display: block">
        <div class="closediv">
          <b-button
            variant="transparent"
            class="closebtn"
            @click="hide()"
          >
            <feather-icon
              icon="XIcon"
              class="cursor-pointer"
            />
          </b-button>
        </div>
        <div
          class="
            d-inline-block d-flex
            justify-content-center
            align-content-center align-items-center
            mt-2
            mb-0
          "
        >
          <p class="font-weight-bolder heading1">
            Add Media
          </p>
        </div>
        <div
          class="
            d-inline-block
            mb-1
            mt-0
            d-flex
            justify-content-center
            align-content-center align-items-center
          "
        >
          <div
            id="form"
            class="form pb-2 w-100"
          >
            <validation-observer ref="addMediaForm">
              <b-form
                class="p-0"
                @submit.prevent
              >
                <div>
                  <b-form-group
                    text-bolder
                    label="Select Type"
                    class="label"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Select Type"
                      rules="required"
                    >
                      <v-select
                        v-model="selectedTitle"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :options="option"
                        class="label"
                        :clearable="false"
                        :disabled="spinner"
                        @input="selectChangeHandler"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>

                <div
                  v-if="
                    selectedTitle &&
                      selectedTitle.title &&
                      selectedTitle.title === 'Image'
                  "
                  class="mb-1"
                >
                  <b-form-group
                    text-bolder
                    label="Duration (seconds)"
                    class="label"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Image Duration"
                    >
                      <b-form-input
                        id="number"
                        v-model="imageDuration"
                        type="number"
                        class="enable-spinners"
                        placeholder="Please enter Image Duration"
                        min="5"
                        max="36000"
                        :disabled="spinner"
                        @input="validateDuration(imageDuration)"
                      />
                      <small class="text-danger">{{
                        errors.length > 0 ? errors[0] : errorMessage
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div
                  v-if="selectedTitle.title === 'Video'"
                >
                  <b-form-group
                    label="Select Orientation"
                    label-size="sm"
                    class="label"
                  >
                    <b-form-radio-group
                      v-model="selectedOrientation"
                      name="orientationRadios"
                    >
                      <b-form-radio
                        value="horizontal"
                        class="small-radio mt-1"
                        :disabled="spinner"
                      >
                        Horizontal
                      </b-form-radio>
                      <b-form-radio
                        value="vertical"
                        class="small-radio"
                        :disabled="spinner"
                      >
                        Vertical
                      </b-form-radio>
                    </b-form-radio-group>
                  </b-form-group>
                  <div v-if="selectedOrientation">
                    <b-form-group
                      label="Select Resolution"
                      class="label"
                    >
                      <v-select
                        v-model="selectedResolution"
                        :options="resolutionOptions"
                        :disabled="spinner"
                        placeholder="Choose resolution"
                      />
                    </b-form-group>
                  </div>
                </div>
                <div
                  v-if="userRole === AccountTypes.ADMIN"
                >
                  <b-form-group
                    text-bolder
                    label="Select Business"
                    class="label"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Select Business"
                      rules="required"
                    >
                      <v-select
                        v-model="selectedBusiness"
                        label="name"
                        :options="businessOptions"
                        :filterable="false"
                        :disabled="spinner"
                        @open="onOpen"
                        @close="onClose"
                      >
                        <template #list-footer>
                          <li
                            v-if="hasNextPage"
                            ref="load"
                            class="loader"
                          >
                            <b-skeleton
                              animation="fade"
                              width="85%"
                            />
                            <b-skeleton
                              animation="fade"
                              width="55%"
                            />
                          </li>
                        </template>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div
                  class="
                    d-flex
                    flex-column
                    justify-content-center
                    align-items-center
                    imageDiv
                  "
                >
                  <b-img
                    class="upload-video-image-div"
                    :src="
                      thumbnailImg
                        ? thumbnailImg
                        : require('@/assets/images/simiicons/Image(2).svg')
                    "
                  />
                  <div class="d-flex w-100 justify-content-center">
                    <b-button
                      class="button mt-2 mx-4"
                      variant="primary"
                      block
                      :disabled="spinner"
                      @click="getFileAndSaveToMediaStorage"
                    >
                      <div
                        v-if="spinner"
                      >
                        <b-spinner
                          sm
                          label="Loading..."
                        />
                      </div>
                      <span
                        v-else
                      >Add</span>
                    </b-button>
                  </div>
                  <div class="small px-2">
                    {{ errorMsg ? errorMsg : null }}
                  </div>
                </div>
              </b-form>
            </validation-observer>
          </div>
        </div>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import {
  BRow,
  BFormInput,
  BButton,
  BForm,
  BFormGroup,
  BImg,
  BSpinner,
  VBTooltip,
  BFormRadio,
  BFormRadioGroup,
  BSkeleton,
} from 'bootstrap-vue'
import AccountTypes from '@/common/enums/accountTypeEnum'
import MediaType from '@/common/enums/mediaTypeEnum'
// import imagePicIcon from '@/assets/images/simiicons/Image(1).svg'
import { showToast, getFormDataFromJSONImage } from '@/common/global/functions'

export default {
  components: {
    BRow,
    BFormInput,
    BButton,
    BForm,
    BFormGroup,
    BImg,
    BSpinner,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BFormRadio,
    BFormRadioGroup,
    BSkeleton,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      errorMsg: null,
      spinner: false,
      selectedTitle: { title: 'Image' },
      option: [{ title: 'Image' }, { title: 'Video' }],
      AccountTypes,
      // validation
      required,
      designData: null,
      thumbnailImg: null,
      accessTokenUser: null,
      isValidAccessToken: null,
      typeOfMedia: 'png',
      imageDuration: null,
      errorMessage: null,
      selectedOrientation: null,
      selectedResolution: null,
      qualityOfFileToDownload: null,
      fileToBeDownloaded: this.initializeFileData(),
      videoDuration: null,
      resolutionOptions: [
        '480p',
        '720p',
        '1080p',
        '4k',
      ],
      businesses: [],
      AllBusinessesList: { results: [], total: 0 },
      limit: 10,
      offset: 0,
      selectedBusiness: null,
      loading: false,
      observer: null,
      search: '',
    }
  },
  computed: {
    userRole() {
      return this.$store.getters['user/getUserRole']
    },
    qualityOfFileToDownloaded() {
      return `${this.selectedOrientation}_${this.selectedResolution}`
    },
    ifImageIsValidDuration() {
      return (this.selectedTitle.title === MediaType.AddMediaTypeImage && this.imageDuration === null)
    },
    ifOrientationOrResolutionSelected() {
      return ((this.selectedTitle.title === MediaType.AddMediaTypeVideo && this.selectedOrientation === null) || (this.selectedTitle.title === MediaType.AddMediaTypeVideo && this.selectedResolution === null))
    },
    businessOptions() {
      if (this.userRole === this.AccountTypes.ADMIN) {
        return this.AllBusinessesList.results.map(business => ({
          name: business.name,
          id: business.id,
        }))
      }
      return null
    },
    hasNextPage() {
      return (this.offset * this.limit) < this.AllBusinessesList.total
    },
    isAdminAndGroupIDAvailable() {
      return this.userRole === this.AccountTypes.ADMIN && this.selectedBusiness && this.selectedBusiness.id
    },
  },
  methods: {
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose() {
      this.observer.disconnect()
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const { scrollTop } = target.offsetParent
        this.offset += 1
        await this.loadAllBusinesses()
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },
    initializeFileData() {
      return {
        file: [],
        thumbnail: [],
        duration: [],
        imageSrc: [],
      }
    },
    async loadAllBusinesses() {
      try {
        this.loading = true
        await this.$axios.get(`admin/user-has-group?offset=${this.offset}&limit=${this.limit}&search_query=`)
          .then(({ data }) => {
            this.AllBusinessesList.results = [
              ...this.AllBusinessesList.results,
              ...data.data.results,
            ]
            this.AllBusinessesList.total = data.data.total
          })
      } catch (error) {
        console.error('Failed to load businesses:', error)
      } finally {
        this.loading = false
      }
    },
    async checkIfAccessTokenISValidElseRefresh() {
      const resp = await this.$store.dispatch('canva/introspectToken', {
        accessToken: this.accessTokenUser.access_token,
      })
      if (!resp) {
        this.resetValuesAndAskUserToLogingAgain('Error validating token. Please try login again!')
      } else {
        this.isValidAccessToken = await this.$store.getters['canva/getIsValid']
        if (!this.isValidAccessToken.active) {
          const response = await this.$store.dispatch(
            'canva/refreshToken',
            { refreshToken: this.accessTokenUser.refresh_token },
          )
          if (!response) {
            this.resetValuesAndAskUserToLogingAgain('Error refreshing token. Please try login again!')
          }
        }
      }
    },
    async show(data) {
      this.designData = data
      this.thumbnailImg = data?.thumbnail?.url
      this.$refs['add-media-modal-1'].show(data)
      if (this.userRole === this.AccountTypes.ADMIN) {
        await this.loadAllBusinesses()
        this.observer = new IntersectionObserver(this.infiniteScroll)
      }
    },
    clearValues() {
      this.imageDuration = null
      this.errorMessage = null
      this.selectedOrientation = null
      this.selectedResolution = null
      this.qualityOfFileToDownload = null
      this.errorMsg = null
      this.videoDuration = null
      this.selectedBusiness = null
      this.AllBusinessesList = { results: [], total: 0 }
      this.fileToBeDownloaded = this.initializeFileData()
    },
    onModalHide() {
      this.hide()
    },
    hide() {
      this.$refs['add-media-modal-1'].hide()
      this.clearValues()
    },
    validateDuration(time) {
      if (time < 5 || time > 36000) {
        this.errorMessage = 'Duration should be between 05 - 36000'
        this.isValidImageDuration = false
      } else {
        this.errorMessage = ''
        this.isValidImageDuration = true
      }
    },
    selectChangeHandler() {
      this.typeOfMedia = this.selectedTitle.title === 'Image' ? 'png' : 'mp4'
    },
    async getFileAndSaveToMediaStorage() {
      const success = await this.$refs.addMediaForm.validate()
      if (this.ifImageIsValidDuration) {
        this.errorMsg = 'Please add Duration to add media'
      } else if (this.ifOrientationOrResolutionSelected) {
        this.errorMsg = 'Please select both Orientation and Resolution'
      } else if (success) {
        this.spinner = true
        try {
          await this.getUrlFromCanva(this.designData.id)
          await this.downloadDesign()
          await this.uploadAllFiles()
        } catch (error) {
          console.error('Error saving files:', error)
        } finally {
          this.spinner = false
          this.hide()
        }
      }
    },

    async getUrlFromCanva(designID) {
      this.accessTokenUser = await JSON.parse(localStorage.getItem('canva-token'))
      await this.checkIfAccessTokenISValidElseRefresh()
      const getUrlCanva = await this.$store.dispatch('canva/downloadDesign', {
        designID,
        accessTokenUser: this.accessTokenUser.access_token,
        typeOfMedia: this.typeOfMedia,
        quality: this.qualityOfFileToDownloaded,
      })
      if (!getUrlCanva) {
        this.ErrorFetching('Error downloading Design. Please try login again!')
      }
      this.downloadURLS = await this.$store.getters['canva/getExportURL']
    },

    async downloadDesign() {
      if (this.downloadURLS.data.job.urls) {
        const blobs = await this.fetchBlobsFromURLs(this.downloadURLS.data.job.urls)
        this.fileToBeDownloaded.file.push(...blobs)

        const thumbnails = await this.fetchBlobsFromURLs(
          blobs.map(() => this.designData?.thumbnail?.url),
        )
        this.fileToBeDownloaded.thumbnail = thumbnails

        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.fileToBeDownloaded.file.length; index++) {
          if (this.typeOfMedia === 'mp4') {
            this.fileToBeDownloaded.duration[index] = this.videoDuration ? Math.ceil(this.videoDuration) : null
          } else {
            this.fileToBeDownloaded.duration[index] = this.imageDuration ? this.imageDuration : null
          }
        }
      }
    },

    async fetchBlobsFromURLs(urls) {
      try {
        const blobs = await Promise.all(
          urls.map(async url => {
            const response = await fetch(url)
            if (!response.ok) throw new Error(`Failed to fetch file from URL: ${url}`)
            const blob = await response.blob()
            let file = null
            if (blob.type === 'image/png') {
              file = new File([blob], `${this.designData.title ? `${this.designData.title}.png` : 'No Name.png'}`, { type: blob.type })
            } else if (blob.type === 'image/webp') {
              file = new File([blob], `${this.designData.title ? `${this.designData.title}.webp` : 'No Name.webp'}`, { type: blob.type })
            } else {
              file = new File([blob], `${this.designData.title ? this.designData.title : 'No Name'}`, { type: blob.type })
              const video = document.createElement('video')
              const objectUrl = URL.createObjectURL(blob)
              video.src = objectUrl

              video.addEventListener('loadedmetadata', async () => {
                const { duration } = video
                URL.revokeObjectURL(objectUrl)
                const decimalPart = duration % 1
                if (decimalPart >= 0.1) {
                  this.videoDuration = Math.ceil(duration)
                } else {
                  this.videoDuration = Math.floor(duration)
                }
              })
            }
            return file
          }),
        )
        return blobs
      } catch (error) {
        console.error('Error fetching blobs:', error)
        throw error
      }
    },
    async uploadAllFiles() {
      const success = []
      const error = []
      const promises = []

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.fileToBeDownloaded.file.length; i++) {
        const formData = getFormDataFromJSONImage(this.fileToBeDownloaded, i)
        promises.push(new Promise(resolve => {
          this.addMediaStorage(formData).then(() => {
            success.push('Success')
          }).catch(e => {
            error.push(e)
            resolve('')
          }).finally(() => resolve(''))
        }))
      }

      await Promise.all(promises)
      if (success.length && error.length) {
        showToast('Add Media', 'Media has been added successfully!', 'success')
        showToast('Add Media', `${error[0]}`, 'danger')
      } else if (success.length && !error.length) {
        showToast('Add Media', 'Media has been added successfully!', 'success')
      } else {
        showToast('Add Media', `${error[0]}`, 'danger')
      }

      this.clearValues()
    },
    async addMediaStorage(formData) {
      if (this.userRole === this.AccountTypes.USER) {
        await this.$axios.post('media', formData)
      } else if (this.selectedBusiness) {
        await this.$axios
          .post('admin/media', formData, {
            headers: { userHasGroupId: this.selectedBusiness.id },
          })
      }
    },

    async resetValuesAndAskUserToLogingAgain(message) {
      showToast('Request Failed', message, 'danger')
      localStorage.removeItem('canva-token')
      localStorage.removeItem('canvaCode')
      this.getAccessTokenFromLocal()
      await this.$store.dispatch('canva/getCanvaCode')
      this.canvaCode = this.$store.getters['canva/getCanvaCode']
      this.spinner = false
    },
  },
}
</script>
<!--<style src="vue-it-bigger/dist/vue-it-bigger.min.css"></style>-->
<style lang="scss">
.add-media-modal {
  .addprofile {
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }
  .title {
    /*width: 100%;*/
    height: 44px;
    background-color: #f8f8f8;
    justify-content: space-between;
    align-items: center;
    display: flex;
    padding-left: 12px;
  }

  .form {
    padding-inline: 10px;
    min-width: 320px !important;
    max-width: 380px !important;
    @media (max-width: 640px) {
      min-width: 320px !important;
      max-width: 320px !important;
    }
    @media (max-width: 560px) {
      min-width: 280px !important;
      max-width: 280px !important;
    }
  }

  .small {
    font-size: 12px;
    line-height: 22px;
    color: #cf0000;
    font-weight: 500;
    font-family: "Montserrat";
    text-align: center;
    margin-top: 10px;
  }

  .choose {
    margin: auto;
    margin-top: 15px;
    display: flex;
    background-color: #e0dee1 !important;
    color: #6e6b7b !important;
    border-color: #e0dee1 !important;
  }

  .choose:focus {
    background-color: #e0dee1 !important;
    color: #6e6b7b !important;
    border-color: #e0dee1 !important;
  }

  .select {
    min-width: 380px;
    height: 38px;
    border-radius: 6px;
    background-color: #ffffff;
    border: 1px solid #d8d6de;
    margin: 5px;
  }

  .head {
    font-size: 14px;
    line-height: 24px;
    color: #1f58b5;
    font-weight: 600;
    font-family: "Montserrat";
  }

  .label {
    font-size: 14px;
    color: #5e5873;
    font-weight: 600;
    font-family: "Montserrat";
  }

  .thumbnaildiv {
    width: 20px;
    height: auto;
  }

  .thumbImg {
    width: 60px;
    height: auto;
  }

  .upload-video-image-div {
    border: dotted #8080803b;
    border-radius: 10px;
    margin: 5px;
    padding: 14px;
    max-width: 170px;
    min-width: 170px;
    min-height: 134px;
    max-height: 160px;
    @media (max-width: 640px) {
      max-width: 140px !important;
      min-width: 140px !important;
    }
    @media (max-width: 600px) {
      max-width: 120px !important;
      min-width: 120px !important;
    }
  }

  .loadedData {
    border: solid #8080803b;
    border-radius: 2px;
  }

  .mediaImage {
    width: 40px;
    height: 40px;
  }
  .media-file {
    width: 151px;
    height: 60px;
    border-radius: 10px;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .cross {
    position: absolute;
    top: -7px;
    left: -5px;
    color: black;
  }
  .image {
    width: 32px !important;
    height: 32px !important;
    border: 2px solid#ececec;
    border-radius: 5px !important;
    box-shadow: 0 1px 7px#0000002e;
  }

  .small-radio {
    font-size: 0.5rem; /* Decreases the text size for radio buttons */
  }
}
.vs__dropdown-menu{
  max-height: 170px !important;
  max-width: 300px !important;
  white-space: nowrap !important;
  overflow-x: hidden !important;
  text-overflow: ellipsis !important;
}
.vs__dropdown-option{
  max-width: 290px !important;
  text-overflow: ellipsis !important;
  overflow-x: hidden !important;
}
.enable-spinners::-webkit-inner-spin-button,
.enable-spinners::-webkit-outer-spin-button {
  appearance: menulist-button !important;
}

.vs__dropdown-menu::-webkit-scrollbar {
  width: 6px !important;

}
.vs__dropdown-menu::-webkit-scrollbar-track {
  background-color: #e4e4e4 !important;
  border-radius: 50px !important;
}

.vs__dropdown-menu::-webkit-scrollbar-thumb {
  background-color: #4c92fc !important;
  border-radius: 50px !important;
}
.vs--disabled .vs__dropdown-toggle, [dir] .vs--disabled .vs__clear, [dir] .vs--disabled .vs__search, [dir] .vs--disabled .vs__selected, [dir] .vs--disabled .vs__open-indicator{
  background-color: #f0eeee !important;
}
</style>
